import React, { useEffect, useState } from "react";
import {
  Flex,
  Button,
  Box,
  Spinner,
  Grid,
  Link,
  Center,
} from "@chakra-ui/react";
import { useBreakpointValue } from "@chakra-ui/react";
import { GridItem, Image } from "@chakra-ui/react";
import Rezerva from "../../components/rezerva/rezerva";

// Define the array of links and labels for the buttons
const buttonsData = [
  { to: "/asia/", label: "Asia" },
  { to: "/africa/", label: "Africa" },
  { to: "/romania/", label: "Romania" },
  { to: "/europa/", label: "Europa" },
  { to: "/americadenord/", label: "America de Nord" },
  { to: "/americadesud/", label: "America de Sud" },
  { to: "/australiasioceania/", label: "Australia si Oceania" },
];

const Blog = () => {
  const [portofolioImages, setPortofolioImages] = useState([]); // Stocam pozele venite de la localHost

  useEffect(() => {
    fetch("/blogdata.json").then((res) => {
      res.json().then((data) => {
        const finalResponse = data.blogdata;
        setPortofolioImages(finalResponse);
      });
    });
  }, []);

  const textResponsive = useBreakpointValue({
    base: "20px",
    md: "30px",
    lg: "35px",
  });
  const widthResponsive = useBreakpointValue({
    base: "100%",
    lg: "50%",
  });

  // Use useBreakpointValue to determine the number of columns
  const gridColumnCount = useBreakpointValue({ base: 2, sm: 2 });
  const fontSizeFilter = useBreakpointValue({ base: "1xl", lg: "6xl" });
  //grid columns
  const columns = useBreakpointValue({ base: 1, lg: 2 });
  const heightImageGrid = useBreakpointValue({ base: "250px", lg: "450px" });

  return (
    <>
      <Rezerva />
      <Center
        className="centerText"
        borderBottom="4px solid teal"
        w={widthResponsive}
        mx="auto"
        fontSize={textResponsive}
        fontWeight="bold"
        color="white"
        textShadow="2px 2px 4px rgba(0, 128, 128)"
        px="6"
        mb="6"
        as="h1"
      >
        Blog - Exploreaza lumea
      </Center>
      <Box
        bgImage="url(https://images.unsplash.com/photo-1526778548025-fa2f459cd5c1?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2050&q=80)" // Set the background image
        bgSize="cover" // Adjust the background size as needed
        bgRepeat="no-repeat" // Set the background repeat
        bgPosition="center" // Set the background position
        p="20px" // Add padding if needed
      >
        <Flex justifyContent="center" mt="10px" w="50%" mx="auto">
          <Grid templateColumns={`repeat(${gridColumnCount}, 2fr)`} gap={3}>
            {buttonsData.map((button, index) => (
              <Link href={button.to} key={index}>
                <Button
                  opacity="0.9"
                  padding="25px"
                  colorScheme="teal"
                  fontSize={fontSizeFilter}
                >
                  {button.label}
                </Button>
              </Link>
            ))}
          </Grid>
        </Flex>
      </Box>
      <Center
        className="centerText"
        borderBottom="4px solid teal"
        w={widthResponsive}
        mx="auto"
        fontSize={textResponsive}
        fontWeight="bold"
        color="white"
        textShadow="2px 2px 4px rgba(0, 128, 128)"
        px="6"
        mb="6"
        as="h2"
      >
        Postari Populare
      </Center>
      {portofolioImages.length > 0 ? (
        <Flex mt="10px" flexWrap="wrap" justifyContent="center">
          <Grid templateColumns={`repeat(${columns}, 1fr)`} gap={1} w="100%">
            {portofolioImages.map((image) => {
              const albumHref = "/" + image.id + "/";

              return (
                <Box key={image.id} mb={6}>
                  <GridItem position="relative" width="95%" mx="auto">
                    <Box
                      className="description-title"
                      mx="auto"
                      p={2}
                      mb={3}
                      boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
                      display="flex"
                      justifyContent="center"
                      background="teal"
                      color="white"
                      fontWeight="bold"
                      fontSize="1.2rem"
                    >
                      {image.description}
                    </Box>
                    <a href={albumHref}>
                      <Image
                        src={image.thumbnail}
                        alt={image.alt}
                        w="100%"
                        h={heightImageGrid}
                        objectFit="cover"
                      />
                    </a>
                    <a href={albumHref}>
                      <Button
                        position="absolute"
                        bottom={0}
                        right={0}
                        size="sm"
                        colorScheme="black"
                        p={3}
                        mb={170}
                        mr={3}
                        fontSize="1.2rem"
                        type="button"
                        border="1px"
                        _hover={{ bg: "white", color: "black" }}
                      >
                        Mai multe informatii
                      </Button>
                    </a>
                    {/* Scrollable text container */}
                    <Box
                      fontSize="1.2rem"
                      maxHeight="150px" // Adjust the height as needed
                      overflowY="auto" // Enable vertical scrolling
                      mt={2} // Adjust the margin top as needed
                      p={2} // Add padding to the scrollable box
                      background="white" // Background color of the scrollable box
                      boxShadow="0px 2px 2px rgba(0, 0, 0, 0.25)" // Optional shadow
                    >
                      {image.text}
                    </Box>
                  </GridItem>
                </Box>
              );
            })}
          </Grid>
        </Flex>
      ) : (
        <Flex justifyContent="center" alignItems="center" height="100vh">
          <Spinner size="xl" />
        </Flex>
      )}
    </>
  );
};

export default Blog;
