import React from "react";
import GoogleMaps from "../../components/google-maps/google-maps";
import ListGroupExample from "../../components/details-contact-page/details-contact-page";
// import FormContact from "../../components/form-contact/formcontact";
import FormContact2222222 from "../../components/form-contact/formcontact";
import Rezerva from "../../components/rezerva/rezerva";

const Contact = () => {
  return (
    <>
      <Rezerva />
      <FormContact2222222 />
      <GoogleMaps />
      <ListGroupExample />
    </>
  );
};

export default Contact;
