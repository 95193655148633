import React from "react";
import {
  Heading,
  Box,
  Link,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "@chakra-ui/react";
import Rezerva from "../../components/rezerva/rezerva";

const ConditiiDeCalatorie = () => {
  return (
    <>
      <Rezerva />
      <Box w="75%" m="auto" border="1px solid #ddd" p={4} mb={4} mt={5}>
        <Heading textAlign="center" mt={4} fontSize="40px">
          <span style={{ padding: "10px" }}>Conditii de calatorie</span>
        </Heading>

        <Table variant="simple" mt={5}>
          <Thead>
            <Tr>
              <Th>Conditie</Th>
              <Th>Informatii oficiale</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>
                Conditii generale de calatorie in statele pentru care nu este
                necesara viza de intrare
              </Td>
              <Td>
                <Link
                  href="https://www.politiadefrontiera.ro/ro/main/pg-conditii-generale-de-calatorie-in-statele-pentru-care-nu-este-necesara-viza-de-intrare-119.html"
                  color="teal"
                  fontWeight="700"
                  _hover={{
                    color: "#DB5461",
                  }}
                  isExternal
                >
                  Click pentru informatii oficiale
                </Link>
              </Td>
            </Tr>
            <Tr>
              <Td>
                Conditii generale de calatorie in statele membre ale Uniunii
                Europene, Spatiului Schengen si Spatiului Economic European
                {"  "}
              </Td>
              <Td>
                <Link
                  href="https://www.politiadefrontiera.ro/ro/main/pg-conditii-generale-de-calatorie-in-statele-membre-ale-uniunii-europene-spatiului-schengen-si-spatiului-economic-european-161.html"
                  color="teal"
                  fontWeight="700"
                  _hover={{
                    color: "#DB5461",
                  }}
                  isExternal
                >
                  Click pentru informatii oficiale
                </Link>
              </Td>
            </Tr>{" "}
            <Tr>
              <Td>Termenul legal de sedere: {"  "}</Td>
              <Td>
                <Link
                  href="https://www.politiadefrontiera.ro/ro/main/pg-termenul-legal-de-sedere-74.html"
                  color="teal"
                  fontWeight="700"
                  _hover={{
                    color: "#DB5461",
                  }}
                  isExternal
                >
                  Click pentru informatii oficiale
                </Link>
              </Td>
            </Tr>{" "}
            <Tr>
              <Td>
                Conditii generale de calatorie in statele pentru care nu este
                necesara viza de intrare
              </Td>
              <Td>
                <Link
                  href="https://www.politiadefrontiera.ro/ro/main/pg-conditii-generale-de-calatorie-in-statele-pentru-care-nu-este-necesara-viza-de-intrare-119.html"
                  color="teal"
                  fontWeight="700"
                  _hover={{
                    color: "#DB5461",
                  }}
                  isExternal
                >
                  Click pentru informatii oficiale
                </Link>
              </Td>
            </Tr>{" "}
            <Tr>
              <Td>Definitii: </Td>
              <Td>
                <Link
                  href="https://www.politiadefrontiera.ro/ro/main/pg-definitii-76.html"
                  color="teal"
                  fontWeight="700"
                  _hover={{
                    color: "#DB5461",
                  }}
                  isExternal
                >
                  Click pentru informatii oficiale
                </Link>
              </Td>
            </Tr>
            <Tr>
              <Td>
                {" "}
                Informatii despre conditiile particulare de intrare si sedere in
                statele membre ale Uniunii Europene, Spatiului Schengen si
                Spatiului Economic European, aplicabile cetatenilor romani.
                {"  "}{" "}
              </Td>
              <Td>
                <Link
                  href="https://www.politiadefrontiera.ro/ro/main/pg-informatii-despre-conditiile-particulare-de-intrare-si-sedere-in-statele-membre-ale-uniunii-europene-spatiului-schengen-si-spatiului-economic-european-aplicabile-cetatenilor-romani-77.html"
                  color="teal"
                  fontWeight="700"
                  _hover={{
                    color: "#DB5461",
                  }}
                  isExternal
                >
                  Click pentru informatii oficiale
                </Link>
              </Td>
            </Tr>{" "}
            <Tr>
              <Td>
                Conditii de iesire din tara pentru cetatenii romani minori{"  "}
              </Td>
              <Td>
                <Link
                  href="https://www.politiadefrontiera.ro/ro/main/pg-conditii-de-iesire-din-tara-pentru-cetatenii-romani-minori-59.html"
                  color="teal"
                  fontWeight="700"
                  _hover={{
                    color: "#DB5461",
                  }}
                  isExternal
                >
                  Click pentru informatii oficiale
                </Link>
              </Td>
            </Tr>{" "}
            <Tr>
              <Td>Cetatenia romana </Td>
              <Td>
                <Link
                  href="https://www.politiadefrontiera.ro/ro/main/pg-cetatenia-romana-164.html"
                  color="teal"
                  fontWeight="700"
                  _hover={{
                    color: "#DB5461",
                  }}
                  isExternal
                >
                  Click pentru informatii oficiale
                </Link>
              </Td>
            </Tr>{" "}
            <Tr>
              <Td>Obligatiile insotitorului {"  "}</Td>
              <Td>
                <Link
                  href="https://www.politiadefrontiera.ro/ro/main/pg-obligatiile-insotitorului-165.html"
                  color="teal"
                  fontWeight="700"
                  _hover={{
                    color: "#DB5461",
                  }}
                  isExternal
                >
                  Click pentru informatii oficiale
                </Link>
              </Td>
            </Tr>{" "}
            <Tr>
              <Td>Intreruperea calatoriei {"  "}</Td>
              <Td>
                <Link
                  href="https://www.politiadefrontiera.ro/ro/main/pg-intreruperea-calatoriei-166.html"
                  color="teal"
                  fontWeight="700"
                  _hover={{
                    color: "#DB5461",
                  }}
                  isExternal
                >
                  Click pentru informatii oficiale
                </Link>
              </Td>
            </Tr>{" "}
            <Tr>
              <Td>Exceptii{"  "}</Td>
              <Td>
                <Link
                  href="https://www.politiadefrontiera.ro/ro/main/pg-exceptii-167.html"
                  color="teal"
                  fontWeight="700"
                  _hover={{
                    color: "#DB5461",
                  }}
                  isExternal
                >
                  Click pentru informatii oficiale
                </Link>
              </Td>
            </Tr>{" "}
            <Tr>
              <Td>Intreruperea calatoriei {"  "}</Td>
              <Td>
                <Link
                  href="https://www.politiadefrontiera.ro/ro/main/pg-intreruperea-calatoriei-166.html"
                  color="teal"
                  fontWeight="700"
                  _hover={{
                    color: "#DB5461",
                  }}
                  isExternal
                >
                  Click pentru informatii oficiale
                </Link>
              </Td>
            </Tr>{" "}
            <Tr>
              <Td>Continutul si forma declaratiei{"  "}</Td>
              <Td>
                <Link
                  href="https://www.politiadefrontiera.ro/ro/main/pg-continutul-si-forma-declaratiei-168.html"
                  color="teal"
                  fontWeight="700"
                  _hover={{
                    color: "#DB5461",
                  }}
                  isExternal
                >
                  Click pentru informatii oficiale
                </Link>
              </Td>
            </Tr>{" "}
            <Tr>
              <Td>
                Conditii generale de calatorie a cetatenilor din statele care nu
                sunt membre ale Uniunii Europene si Spatiului Economic European
                {"  "}
              </Td>
              <Td>
                <Link
                  href="https://www.politiadefrontiera.ro/ro/main/pg-conditii-generale-de-calatorie-a-cetatenilor-din-statele-care-nu-sunt-membre-ale-uniunii-europene-si-spatiului-economic-european-147.html"
                  color="teal"
                  fontWeight="700"
                  _hover={{
                    color: "#DB5461",
                  }}
                  isExternal
                >
                  Click pentru informatii oficiale
                </Link>
              </Td>
            </Tr>{" "}
            <Tr>
              <Td>
                Documentele de trecere a frontierei acceptate de statul roman
                {"  "}
              </Td>
              <Td>
                <Link
                  href="https://www.politiadefrontiera.ro/ro/main/pg-documentele-de-trecere-a-frontierei-acceptate-de-statul-roman-149.html"
                  color="teal"
                  fontWeight="700"
                  _hover={{
                    color: "#DB5461",
                  }}
                  isExternal
                >
                  Click pentru informatii oficiale
                </Link>
              </Td>
            </Tr>{" "}
            <Tr>
              <Td> Viza romana{"  "}</Td>
              <Td>
                <Link
                  href="https://www.politiadefrontiera.ro/ro/main/pg-viza-romana-150.html"
                  color="teal"
                  fontWeight="700"
                  _hover={{
                    color: "#DB5461",
                  }}
                  isExternal
                >
                  Click pentru informatii oficiale
                </Link>
              </Td>
            </Tr>{" "}
            <Tr>
              <Td> Documente justificative{"  "}</Td>
              <Td>
                <Link
                  href="https://www.politiadefrontiera.ro/ro/main/pg-documente-justificative-151.html"
                  color="teal"
                  fontWeight="700"
                  _hover={{
                    color: "#DB5461",
                  }}
                  isExternal
                >
                  Click pentru informatii oficiale
                </Link>
              </Td>
            </Tr>{" "}
            <Tr>
              <Td>Asigurarea medicala{"  "}</Td>
              <Td>
                <Link
                  href="https://www.politiadefrontiera.ro/ro/main/pg-asigurarea-medicala-152.html"
                  color="teal"
                  fontWeight="700"
                  _hover={{
                    color: "#DB5461",
                  }}
                  isExternal
                >
                  Click pentru informatii oficiale
                </Link>
              </Td>
            </Tr>{" "}
            <Tr>
              <Td> Regimul aplicabil micului trafic de frontiera{"  "}</Td>
              <Td>
                <Link
                  href="https://www.politiadefrontiera.ro/ro/main/pg-regimul-aplicabil-micului-trafic-de-frontiera-153.html"
                  color="teal"
                  fontWeight="700"
                  _hover={{
                    color: "#DB5461",
                  }}
                  isExternal
                >
                  Click pentru informatii oficiale
                </Link>
              </Td>
            </Tr>{" "}
            <Tr>
              <Td>Refuzul intrarii/iesirii in/din Romania{"  "}</Td>
              <Td>
                <Link
                  href="https://www.politiadefrontiera.ro/ro/main/pg-refuzul-intrariiiesirii-indin-romania-154.html"
                  color="teal"
                  fontWeight="700"
                  _hover={{
                    color: "#DB5461",
                  }}
                  isExternal
                >
                  Click pentru informatii oficiale
                </Link>
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </Box>
    </>
  );
};

export default ConditiiDeCalatorie;
