import React from "react";
import {
  MDBFooter,
  MDBContainer,
  MDBCol,
  MDBRow,
  MDBIcon,
  MDBBtn,
} from "mdb-react-ui-kit";
import { Link } from "react-router-dom";
import NTPLogo from "ntp-logo-react";

import "./footer.css";

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <MDBFooter bgColor="light" className="text-center text-lg-start text-muted">
      <section className="">
        <MDBContainer className="text-center text-md-start mt-5">
          <MDBRow className="mt-3">
            <MDBCol
              md="3"
              lg="3"
              xl="3"
              className="mx-auto mb-4 justify-content-center"
            >
              <h2 className="text-uppercase fw-bold mb-4">
                <MDBIcon icon="gem" className="me-3" />
                TRAVEL TIME D&R
              </h2>
              <p className="text-start">
                Travel Time, o companie intemeiata in 2006, ca rezultat a 20 de
                ani de experienta in cadrul Industriei Turistice, activeaza pe
                partea de Corporate si Turism Recreativ si este prezenta atat in
                turismul intern cat si in cel extern. Echipa noastra este
                pregatita sa iti raspunda la telefon sau e-mail si nu trebuie sa
                iti faci griji daca ai nevoie de ajutor in afara programului, un
                agent de turism este intotdeauna pregatit sa te ajute in
                problemele pe care le poti intampina!
              </p>
            </MDBCol>

            <MDBCol md="3" lg="2" xl="2" className="mx-auto mb-4">
              <h3 className="text-uppercase fw-bold mb-4">Link-uri Utile</h3>
              {/* <ul>
                <Link to={"/vacante"} onClick={() => scrollToTop()}>
                  Vacante
                </Link>
              </ul> */}
              <ul>
                <Link to={"/corporate"} onClick={() => scrollToTop()}>
                  Corporate Travel
                </Link>
              </ul>
              <ul>
                <Link to={"/leisure"} onClick={() => scrollToTop()}>
                  Leisure Travel
                </Link>
              </ul>
              <ul>
                <Link to={"/blog"} onClick={() => scrollToTop()}>
                  Blog
                </Link>
              </ul>
              <ul>
                <Link to={"/europa"} onClick={() => scrollToTop()}>
                  Europa
                </Link>
              </ul>
              <ul>
                <Link to={"/africa"} onClick={() => scrollToTop()}>
                  Africa
                </Link>
              </ul>
              <ul>
                <Link to={"/asia"} onClick={() => scrollToTop()}>
                  Asia
                </Link>
              </ul>
              <ul>
                <Link to={"/romania"} onClick={() => scrollToTop()}>
                  Romania
                </Link>
              </ul>
              <ul>
                <Link to={"/americadesud"} onClick={() => scrollToTop()}>
                  America de Sud
                </Link>
              </ul>
              <ul>
                <Link to={"/americadenord"} onClick={() => scrollToTop()}>
                  America de Nord
                </Link>
              </ul>
              <ul>
                <Link to={"/australiasioceania"} onClick={() => scrollToTop()}>
                  Australia si Oceania
                </Link>
              </ul>
              {/* <ul>
                <Link to={"/revelion"} onClick={() => scrollToTop()}>
                  Revelion 2024
                </Link>
              </ul> */}
              <ul>
                <Link to={"/agentii-partenere"} onClick={() => scrollToTop()}>
                  Agentii Partenere
                </Link>
              </ul>
              <ul>
                <Link to={"/conditiidecalatorie"} onClick={() => scrollToTop()}>
                  Conditii de Calatorie
                </Link>
              </ul>
              <ul>
                <Link
                  to={"/comunicat-de-presa-proiect-lansare"}
                  onClick={() => scrollToTop()}
                >
                  Comunicat de Presa Proiect Lansare
                </Link>
              </ul>
              <ul>
                <Link
                  to={"/comunicat-de-presa-proiect-finalizare"}
                  onClick={() => scrollToTop()}
                >
                  Comunicat de Presa Proiect Finalizare
                </Link>
              </ul>
              <ul>
                <Link
                  to={"/comunicat-finalizare-proiect-ID-131118"}
                  onClick={() => scrollToTop()}
                >
                  Comunicat finalizare proiect -ID 131118
                </Link>
              </ul>
              <ul>
                <Link to={"/contact"} onClick={() => scrollToTop()}>
                  Contact
                </Link>
              </ul>
            </MDBCol>

            <MDBCol md="4" lg="3" xl="3" className="mx-auto mb-md-0 mb-4">
              <h4 className="text-uppercase fw-bold mb-4">Contact</h4>
              <p>
                <MDBIcon icon="home" className="me-2" />
                Strada Mircea Vulcanescu, nr 45, Sector 1, Bucuresti
              </p>
              <p>
                <MDBIcon icon="envelope" className="me-3" />
                <strong>
                  Adresa Generala:
                  <a href="mailto:office@travel-time.ro">
                    {" "}
                    office@travel-time.ro
                  </a>
                </strong>
              </p>
              <p>
                <MDBIcon icon="envelope" className="me-3" />
                <strong>
                  Departament Ticketing:
                  <a href="mailto:ticketing@travel-time.ro">
                    {" "}
                    ticketing@travel-time.ro
                  </a>
                </strong>
              </p>
              <p>
                <MDBIcon icon="envelope" className="me-3" />
                <strong>
                  Departament Turism:
                  <a href="mailto:turism@travel-time.ro">
                    {" "}
                    turism@travel-time.ro
                  </a>
                </strong>
              </p>
              <p>
                <MDBIcon icon="phone" className="me-3" />{" "}
                <a href="tel:0371121272">0371 12 12 72</a>
              </p>
              <MDBBtn
                floating
                className="m-1"
                style={{ backgroundColor: "#3b5998" }}
                href="https://www.facebook.com/traveltimeromania"
                role="button"
              >
                <MDBIcon fab icon="facebook-f" />
              </MDBBtn>

              <MDBBtn
                floating
                className="m-1"
                style={{ backgroundColor: "#ac2bac" }}
                href="https://www.instagram.com/traveltime.ro/"
                role="button"
              >
                <MDBIcon fab icon="instagram" />
              </MDBBtn>
            </MDBCol>
            <MDBCol md="4" lg="3" xl="3" className="mx-auto mb-md-0 mb-4">
              <h5 className="text-uppercase fw-bold mb-4">
                Date de Indentificare
              </h5>
              <p>SC TRAVEL TIME D&R SRL</p>
              <p>
                Sediul Social:Adresa : Strada ANTON PANN, Nr. 2, parter, ap.3,
                sector 3, Bucuresti
              </p>
              <p>CIF: RO17926970</p>
              <p>Nr. Reg. Com.: J40/15226/2005</p>

              <MDBRow className="d-flex justify-content-center">
                <img
                  src="../images/iata.png"
                  alt="Logo 1"
                  style={{ width: "80px", height: "35px" }}
                />
                <img
                  src="../images/anat.png"
                  alt="Logo 2"
                  style={{ width: "80px", height: "40px" }}
                />
                <img
                  src="../images/logo-travelnation.png"
                  alt="Logo 3"
                  style={{ width: "150px", height: "50px" }}
                />
              </MDBRow>
              <MDBRow className="d-flex justify-content-center">
                <NTPLogo color="#74deec" version="orizontal" secret="136590" />
              </MDBRow>
              <MDBRow className="d-flex justify-content-center mt-3">
                <div className="col-6 col-md-auto text-center">
                  <a
                    href="https://anpc.ro/ce-este-sal/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="../images/SOL.png"
                      alt="Logo 1"
                      style={{ width: "140px", height: "35px" }}
                    />
                  </a>
                </div>
                <div className="col-6 col-md-auto text-center">
                  <a
                    href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=EN"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="../images/SAL.png"
                      alt="Logo 2"
                      style={{ width: "140px", height: "40px" }}
                    />
                  </a>
                </div>
              </MDBRow>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      <div
        className="text-center p-4 text-reset fw-bold"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
      >
        <div className="w-75 mx-auto">
          <b>SC TRAVEL TIME D&R SRL</b>
          {" | "}
          <b>
            Sediul Social:Adresa : Strada ANTON PANN, Nr. 2, parter, ap.3,sector
            3, Bucuresti
          </b>
          {" | "}
          <a
            href="https://www.travel-time.ro/images/documente/CUI%20TRAVEL%20TIME.pdf"
            target="_blank"
            rel="noreferrer"
          >
            CIF: RO17926970
          </a>
          {" | "} <b>Nr. Reg. Com.: J40/15226/2005</b>
          {" | "}
          <a
            href="https://www.travel-time.ro/images/documente/Licenta%20turism%20TRAVEL%20TIME%20D&R%20.pdf"
            target="_blank"
            rel="noreferrer"
          >
            Licenta de turism: 236/ 04.12.2018
          </a>
          {" | "}
          <a
            href="https://www.travel-time.ro/images/documente/BREVET%20Turism.pdf"
            target="_blank"
            rel="noreferrer"
          >
            Brevet Turism
          </a>
          {/* {" | "}
          <a href="">Certificat Membru Anat</a> */}
          {" | "}
          <a
            href="https://www.travel-time.ro/images/documente/Polita%20insolvabilitate%20TRAVEL%20TIME%20D&R.pdf"
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            Polita Asigurare
          </a>
          {" | "}
          <a
            href="https://www.travel-time.ro/images/documente/certificat%20ISO%202023.pdf"
            target="_blank"
            rel="noreferrer"
          >
            Certificat ISO
          </a>
          {" | "}
          <a
            href="https://www.travel-time.ro/images/documente/Politica%20de%20cookie%20Travel%20Time.pdf"
            target="_blank"
            rel="noreferrer"
          >
            Politica Cookies
          </a>
          {" | "}
          <a
            href="https://www.travel-time.ro/images/documente/Politica%20de%20confidentialitate%20travel%20time.pdf"
            target="_blank"
            rel="noreferrer"
          >
            Politica de Confidentialitate
          </a>
          {" | "}
          <a
            href="https://www.travel-time.ro/images/documente/Draft%20Contract%20Turist.pdf"
            target="_blank"
            rel="noreferrer"
          >
            Draft - Contract cu Turistul
          </a>
          {" | "}
          <a href="https://anpc.ro/" target="_blank" rel="noreferrer">
            Autoritatea Nationala pentru Protectia Consumatorilor
          </a>
          {" | "}
          <a
            href="https://legislatie.just.ro/Public/DetaliiDocument/257649?fs=e&s=cl"
            target="_blank"
            rel="noreferrer"
          >
            Masurile obligatorii ANPC
          </a>
          {" | "}
          <Link to={"/termenisiconditii"} onClick={() => scrollToTop()}>
            Termeni si Conditii
          </Link>
        </div>
        <p className="pt-5">© 2024 Copyright. Travel Time D&R</p>
      </div>
    </MDBFooter>
  );
};

export default Footer;
